import {
  Component,
  Inject,
  EventEmitter,
  Output,
  inject,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { TransportistaService } from '../../services/api/transportista.service';
import { RutaService } from '../../services/api/ruta.service';
import { lastValueFrom } from 'rxjs';
import { HabitualsListComponent } from './habitualsList.component';
import { VehicleService } from '../../services/api/vehicle.service';
import { HabitualService } from '../../services/habitual.service';
import { MatTooltip } from '@angular/material/tooltip';
import { ErrorModalComponent } from '../error-modal/error-modal.component';
import { ContextService } from '../../services/api/context.service';
import { Vehicle } from '../../types/Vehicle';
import { Transportista } from '../../types/Transportista';
import { Ruta } from '../../types/Ruta';

@Component({
  selector: 'app-habituals-edit',
  template: `
    <div class="ctti-modal text-left" [formGroup]="form">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      @if(!data.edit) {
      <h5 class="title5">{{ 'MODAL.DADES_HABITUALS_TITLE' | translate }}</h5>
      } @else { @if(data.data?.objectMantId) {
      <h5 class="title5">
        <mat-icon>edit</mat-icon>{{ 'MODAL.HABITUALS_EDIT' | translate }}
      </h5>
      <p>{{ 'MODAL.HABITUALS_EDIT_SUB' | translate }}</p>
      } @else {
      <h5 class="title5">
        <mat-icon>add</mat-icon>{{ 'MODAL.HABITUALS_NEW' | translate }}
      </h5>
      <p>{{ 'MODAL.HABITUALS_NEW_SUB' | translate }}</p>
      } }
      <hr />
      <br />
      @if(data.type == "ruta"){
      <div class="grid-2">
        <div class="col mb-3">
          <label for="alias"
            ><span class="red">*</span>{{ 'STMC.ALIES' | translate }}:</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="alias"
            class="ctti-input-text"
            formControlName="alias"
            placeholder="{{ 'STMC.ALIES' | translate }}..."
            matTooltip="{{ tooltipText | translate }}"
            #aliasTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          } @else {
          <p>{{ form.value.alias }}</p>
          }
        </div>
        <div></div>
      </div>
      <h5>{{ 'STMC.ORIGIN' | translate }}</h5>
      <div class="col mb-3">
        <label for="adrecaOrigen">{{ 'STMC.ADDRESS' | translate }}:</label>
        @if(data.edit) {
        <input
          type="text"
          id="adrecaOrigen"
          class="ctti-input-text"
          formControlName="adrecaOrigen"
          placeholder="{{ 'STMC.ADDRESS_PLACEHOLDER' | translate }}"
        />
        } @else {
        <p>{{ form.value.adrecaOrigen }}</p>
        }
      </div>
      <div class="grid-2">
        <div class="col">
          <label for="codiPostalOrigen"
            >{{ 'STMC.POSTAL_CODE' | translate }}:</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="codiPostalOrigen"
            class="ctti-input-text"
            formControlName="codiPostalOrigen"
            placeholder="{{ 'STMC.POSTAL_CODE_PLACEHOLDER' | translate }}"
          />
          }@else {
          <p>{{ form.value.codiPostalOrigen }}</p>
          }
        </div>

        <div class="col">
          <label for="codiProvinciaOrigen"
            >{{ 'STMC.PROVINCE' | translate }}:</label
          >
          @if(data.edit) {
          <select
            id="codiProvinciaOrigen"
            formControlName="codiProvinciaOrigen"
            placeholder="Selecciona una opció"
            #dropdown
            placeholder="Selecciona una opció"
            class="ctti-dropdown"
            [ngClass]="form.value.codiProvinciaOrigen ? 'enDada' : ''"
          >
            <option value="" selected>
              {{ 'STMC.PROVINCE_PLACEHOLDER' | translate }}
            </option>
            <option *ngFor="let province of provinces" [value]="province.value">
              {{ province.label }}
            </option>
          </select>
          }@else {
          <p>{{ getProvincia(form.value.codiProvinciaOrigen) }}</p>
          }
        </div>

        <div class="col">
          <label for="poblacioOrigen"
            ><span class="red">*</span>{{ 'STMC.CITY' | translate }}:</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="poblacioOrigen"
            class="ctti-input-text"
            formControlName="poblacioOrigen"
            (focus)="onFocus('poblacioOrigen')"
            (blur)="onBlur('poblacioOrigen')"
            (input)="changeContextPoblacio($event, 'origen')"
            placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #poblacioOrigenTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          <ul
            *ngIf="poblacionsOrigen.length > 0 && isFocused.poblacioOrigen"
            class="contextList"
          >
            <li
              *ngFor="let poblacio of poblacionsOrigen"
              (click)="setFormValue('poblacioOrigen', poblacio)"
            >
              {{ poblacio.descripcio }}
            </li>
          </ul>
          } @else {
          <p>{{ form.value.poblacioOrigen }}</p>
          }
        </div>
      </div>

      <br />

      <h5>{{ 'STMC.DESTINATION' | translate }}</h5>
      <div class="col mb-3">
        <label for="adrecaDesti">{{ 'STMC.ADDRESS' | translate }}:</label>
        @if(data.edit) {
        <input
          type="text"
          id="adrecaDesti"
          class="ctti-input-text"
          formControlName="adrecaDesti"
          placeholder="{{ 'STMC.ADDRESS_PLACEHOLDER' | translate }}"
        />
        }@else {
        <p>{{ form.value.adrecaDesti }}</p>
        }
      </div>
      <div class="grid-2">
        <div class="col">
          <label for="codiPostalDesti"
            >{{ 'STMC.POSTAL_CODE' | translate }}:</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="codiPostalDesti"
            class="ctti-input-text"
            formControlName="codiPostalDesti"
            placeholder="{{ 'STMC.POSTAL_CODE_PLACEHOLDER' | translate }}"
          />
          } @else {
          <p>{{ form.value.codiPostalDesti }}</p>
          }
        </div>
        <div class="col">
          <label for="codiProvinciaDesti"
            >{{ 'STMC.PROVINCE' | translate }}:</label
          >
          @if(data.edit) {
          <select
            id="codiProvinciaDesti"
            formControlName="codiProvinciaDesti"
            placeholder="Selecciona una opció"
            #dropdown
            class="ctti-dropdown"
            [ngClass]="form.value.codiProvinciaDesti ? 'enDada' : ''"
          >
            <option value="" selected>
              {{ 'STMC.PROVINCE_PLACEHOLDER' | translate }}
            </option>
            <option *ngFor="let province of provinces" [value]="province.value">
              {{ province.label }}
            </option>
          </select>
          }@else {
          <p>{{ getProvincia(form.value.codiProvinciaDesti) }}</p>
          }
        </div>
        <div class="col">
          <label for="poblacioDesti"
            ><span class="red">*</span>{{ 'STMC.CITY' | translate }}:</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="poblacioDesti"
            class="ctti-input-text"
            formControlName="poblacioDesti"
            (focus)="onFocus('poblacioDesti')"
            (blur)="onBlur('poblacioDesti')"
            (input)="changeContextPoblacio($event, 'desti')"
            placeholder="{{ 'STMC.CITY_PLACEHOLDER' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #poblacioDestiTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          <ul
            *ngIf="poblacionsDesti.length > 0 && isFocused.poblacioDesti"
            class="contextList"
          >
            <li
              *ngFor="let poblacio of poblacionsDesti"
              (click)="setFormValue('poblacioDesti', poblacio)"
            >
              {{ poblacio.descripcio }}
            </li>
          </ul>
          }@else{
          <p>{{ form.value.poblacioDesti }}</p>
          }
        </div>
      </div>

      } @if(data.type == "transportista") {
      <div class="grid-2">
        <div class="col">
          <label for="alias"
            ><span class="red">*</span>{{ 'STMC.ALIES' | translate }}:</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="alias"
            class="ctti-input-text"
            formControlName="alias"
            placeholder="{{ 'STMC.ALIES' | translate }}..."
            matTooltip="{{ tooltipText | translate }}"
            #aliasTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          } @else {
          <p>{{ form.value.alias }}</p>
          }
        </div>
        <div class="col">
          <label for="raoSocial"
            ><span class="red">*</span
            >{{ 'STMC.COMPANY_NAME' | translate }}</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="raoSocial"
            class="ctti-input-text"
            formControlName="raoSocial"
            placeholder="{{ 'STMC.COMPANY_NAME_PLACEHOLDER' | translate }}"
            (focus)="onFocus('raoSocial')"
            (blur)="onBlur('raoSocial')"
            (input)="changeContextEmpresa($event, 'TRANSPORTISTA')"
            matTooltip="{{ tooltipText | translate }}"
            #raoSocialTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          <ul
            *ngIf="resultados.length > 0 && isFocused.raoSocial"
            class="contextList"
          >
            <li
              *ngFor="let resultado of resultados"
              (click)="setFormValue('raoSocial', resultado)"
            >
              {{ resultado.raoSocial }}
            </li>
          </ul>
          }@else {
          <p>{{ form.value.raoSocial }}</p>
          }
        </div>

        <div class="col">
          <label for="nifTransportista"
            ><span class="red">*</span
            >{{ 'STMC.COMPANY_NIF' | translate }}</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="nifTransportista"
            class="ctti-input-text"
            formControlName="nifTransportista"
            placeholder="{{ 'STMC.COMPANY_NIF_PLACEHOLDER' | translate }}"
            (focus)="onFocus('nif')"
            (blur)="onBlur('nif')"
            (input)="changeContextEmpresa($event, 'TRANSPORTISTA')"
            matTooltip="{{ tooltipText | translate }}"
            #nifTransportistaTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          <ul
            *ngIf="resultados.length > 0 && isFocused.nif"
            class="contextList"
          >
            <li
              *ngFor="let resultado of resultados"
              (click)="setFormValue('nif', resultado)"
            >
              {{ resultado.nif }}
            </li>
          </ul>
          } @else {
          <p>{{ form.value.nifTransportista }}</p>
          }
        </div>
      </div>
      } @if(data.type == "vehicle") {
      <div class="grid-2">
        <div class="col">
          <label for="tipusVehicle"
            ><span class="red">*</span
            >{{ 'STMC.TIPUS_VEHICLE' | translate }}</label
          >
          @if(data.edit) {
          <div class="radio-buttons">
            <label>
              <input
                type="radio"
                formControlName="tipusVehicle"
                value="TRACTOR"
              />
              {{ 'STMC.TRACTOR' | translate }}
            </label>
            <label>
              <input
                type="radio"
                formControlName="tipusVehicle"
                value="REMOLC"
              />
              {{ 'STMC.REMOLC' | translate }}
            </label>
          </div>

          } @else {
          <p>{{ form.value.tipusVehicle }}</p>
          }
        </div>
        <div class="col">
          <label for="matricula"
            ><span class="red">*</span
            >{{ 'STMC.LICENSE_PLATE' | translate }}</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="matricula"
            class="ctti-input-text"
            formControlName="matricula"
            placeholder="{{ 'STMC.LICENSE_PLATE' | translate }}"
            matTooltip="{{ tooltipText | translate }}"
            #matriculaTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          }@else {
          <p>{{ form.value.matricula }}</p>
          }
        </div>
        <div class="col">
          <label for="alias"
            ><span class="red">*</span>{{ 'STMC.ALIES' | translate }}:</label
          >
          @if(data.edit) {
          <input
            type="text"
            id="alias"
            class="ctti-input-text"
            formControlName="alias"
            placeholder="{{ 'STMC.ALIES' | translate }}..."
            matTooltip="{{ tooltipText | translate }}"
            #aliasTooltipRef="matTooltip"
            matTooltipPosition="above"
          />
          } @else {
          <p>{{ form.value.alias }}</p>
          }
        </div>
        <div class="col">
          <label for="observacions">{{ 'STMC.DESCRIPCIO' | translate }}</label>
          @if(data.edit) {
          <input
            type="text"
            formControlName="observacions"
            id="observacions"
            class="ctti-input-text"
            placeholder="{{ 'STMC.DESCRIPCIO' | translate }}"
          />
          } @else {
          <p>{{ form.value.observacions }}</p>
          }
        </div>
      </div>
      }
      <br /><br />
      <div class="botones">
        @if(data.edit) {
        <button
          class="ctti-button ctti-button-trans"
          mat-dialog-close
          (click)="closeModal()"
        >
          {{ 'MODAL.CANCEL' | translate }}
        </button>
        <button
          class="ctti-button ctti-button-filled"
          mat-dialog-close
          (click)="closeModal(true)"
        >
          <mat-icon>save</mat-icon>
          @if(data.data?.objectMantId) {
          {{ 'MODAL.SAVE_CANVIS' | translate }}
          } @else {
          {{ 'MODAL.SAVE' | translate }}
          }
        </button>
        } @else {@if(this.data.openList){
        <button
          class="ctti-button ctti-button-filled"
          mat-dialog-close
          (click)="selectItem()"
        >
          {{ 'MODAL.SELECT' | translate }}
        </button>
        }}
      </div>
    </div>
  `,
})
export class HabitualsEditComponent {
  rutaService = inject(RutaService);
  vehicleService = inject(VehicleService);
  transportistaService = inject(TransportistaService);
  @Output() closeEvent = new EventEmitter<void>();
  contextService = inject(ContextService);
  poblacionsOrigen: any[] = [];
  poblacionsDesti: any[] = [];
  form: FormGroup;
  rowdata: any;
  tooltipText = '';
  codiPoblacioOrigen: string = '';
  codiPoblacioDesti: string = '';
  isFocused = {
    poblacioDesti: false,
    poblacioOrigen: false,
    nif: false,
    raoSocial: false,
  };
  resultados: any[] = [];

  @ViewChild('aliasTooltipRef') aliasTooltipRef!: MatTooltip;
  @ViewChild('poblacioOrigenTooltipRef') poblacioOrigenTooltipRef!: MatTooltip;
  @ViewChild('poblacioDestiTooltipRef') poblacioDestiTooltipRef!: MatTooltip;
  @ViewChild('raoSocialTooltipRef') raoSocialTooltipRef!: MatTooltip;
  @ViewChild('nifTransportistaTooltipRef')
  nifTransportistaTooltipRef!: MatTooltip;
  @ViewChild('matriculaTooltipRef') matriculaTooltipRef!: MatTooltip;
  @ViewChild('tipusVehicleTooltipRef') tipusVehicleTooltipRef!: MatTooltip;

  provinces: { value: string; label: string }[] = [
    { value: '01', label: 'Álava' },
    { value: '02', label: 'Albacete' },
    { value: '03', label: 'Alicante' },
    { value: '04', label: 'Almería' },
    { value: '05', label: 'Ávila' },
    { value: '06', label: 'Badajoz' },
    { value: '07', label: 'Islas Baleares' },
    { value: '08', label: 'Barcelona' },
    { value: '09', label: 'Burgos' },
    { value: '10', label: 'Cáceres' },
    { value: '11', label: 'Cádiz' },
    { value: '12', label: 'Castellón' },
    { value: '13', label: 'Ciudad Real' },
    { value: '14', label: 'Córdoba' },
    { value: '15', label: 'A Coruña' },
    { value: '16', label: 'Cuenca' },
    { value: '17', label: 'Girona' },
    { value: '18', label: 'Granada' },
    { value: '19', label: 'Guadalajara' },
    { value: '20', label: 'Guipúzcoa' },
    { value: '21', label: 'Huelva' },
    { value: '22', label: 'Huesca' },
    { value: '23', label: 'Jaén' },
    { value: '24', label: 'León' },
    { value: '25', label: 'Lleida' },
    { value: '26', label: 'La Rioja' },
    { value: '27', label: 'Lugo' },
    { value: '28', label: 'Madrid' },
    { value: '29', label: 'Málaga' },
    { value: '30', label: 'Murcia' },
    { value: '31', label: 'Navarra' },
    { value: '32', label: 'Ourense' },
    { value: '33', label: 'Asturias' },
    { value: '34', label: 'Palencia' },
    { value: '35', label: 'Las Palmas' },
    { value: '36', label: 'Pontevedra' },
    { value: '37', label: 'Salamanca' },
    { value: '38', label: 'Santa Cruz de Tenerife' },
    { value: '39', label: 'Cantabria' },
    { value: '40', label: 'Segovia' },
    { value: '41', label: 'Sevilla' },
    { value: '42', label: 'Soria' },
    { value: '43', label: 'Tarragona' },
    { value: '44', label: 'Teruel' },
    { value: '45', label: 'Toledo' },
    { value: '46', label: 'València' },
    { value: '47', label: 'Valladolid' },
    { value: '48', label: 'Vizcaya' },
    { value: '49', label: 'Zamora' },
    { value: '50', label: 'Zaragoza' },
    { value: '51', label: 'Ceuta' },
    { value: '52', label: 'Melilla' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      type: string;
      data: Ruta | Vehicle | Transportista | null;
      edit: boolean;
      openList: boolean;
    },
    private fb: FormBuilder,
    private dialog: MatDialog,
    private habitualService: HabitualService
  ) {
    if (this.data.type == 'ruta') {
      this.form = this.fb.group({
        adrecaOrigen: [''],
        codiPostalOrigen: [''],
        poblacioOrigen: ['', Validators.required],
        codiProvinciaOrigen: [''],
        adrecaDesti: [''],
        codiPostalDesti: [''],
        poblacioDesti: ['', Validators.required],
        codiProvinciaDesti: [''],
        alias: ['', Validators.required],
      });
    } else if (this.data.type == 'transportista') {
      this.form = this.fb.group({
        raoSocial: ['', Validators.required],
        nifTransportista: ['', Validators.required],
        alias: ['', Validators.required],
      });
    } else {
      this.form = this.fb.group({
        tipusVehicle: ['TRACTOR', Validators.required],
        matricula: ['', Validators.required],
        alias: ['', Validators.required],
        observacions: [''],
      });
    }
  }

  async ngOnInit(): Promise<void> {
    if (!this.data.data) {
      return;
    }
    this.rowdata = this.data.data;
    if (this.data.type == 'ruta') {
      this.codiPoblacioOrigen = this.rowdata.codiPoblacioOrigen,
      this.codiPoblacioDesti = this.rowdata.codiPoblacioDesti,
      this.form.patchValue({
        adrecaOrigen: this.rowdata.adrecaOrigen,
        adrecaDesti: this.rowdata.adrecaDesti,
        codiPostalOrigen: this.rowdata.codiPostalOrigen,
        codiPostalDesti: this.rowdata.codiPostalDesti,
        poblacioOrigen: this.rowdata.poblacioOrigen,
        poblacioDesti: this.rowdata.poblacioDesti,
        codiProvinciaOrigen: this.rowdata.codiProvinciaOrigen || '',
        codiProvinciaDesti: this.rowdata.codiProvinciaDesti || '',
        alias: this.rowdata.alias,
      });
      this.codiPoblacioDesti = this.rowdata.codiPoblacioDesti;
      this.codiPoblacioOrigen = this.rowdata.codiPoblacioOrigen;
    } else if (this.data.type == 'transportista') {
      this.form.patchValue({
        raoSocial: this.rowdata.raoSocial,
        nifTransportista: this.rowdata.nifTransportista,
        alias: this.rowdata.alias,
      });
    } else {
      this.form.patchValue({
        matricula: this.rowdata.matricula,
        tipusVehicle: this.rowdata.tipusVehicle,
        alias: this.rowdata.alias,
        observacions: this.rowdata.observacions,
      });
    }
  }

  async changeContextEmpresa($event: any, rol: string) {
    if ($event.target.value.length >= 3) {
      this.resultados = await lastValueFrom(
        this.contextService.getEmpreses($event.target.value, rol)
      );
    }
  }

  selectItem() {
    this.habitualService.emitHabitual(this.data.type, this.rowdata);
    this.closeModal();
  }

  onFocus(campo: string) {
    switch (campo) {
      case 'poblacioOrigen':
        this.isFocused.poblacioOrigen = true;
        break;
      case 'poblacioDesti':
        this.isFocused.poblacioDesti = true;
        break;
      case 'nif':
        this.isFocused.nif = true;
        break;
      case 'raoSocial':
        this.isFocused.raoSocial = true;
    }
  }

  onBlur(campo: string) {
    setTimeout(() => {
      switch (campo) {
        case 'poblacioOrigen':
          this.isFocused.poblacioOrigen = false;
          this.poblacionsOrigen = [];
          break;
        case 'poblacioDesti':
          this.isFocused.poblacioDesti = false;
          this.poblacionsDesti = [];
          break;
        case 'nif':
          this.isFocused.nif = false;
          this.poblacionsDesti = [];
          break;
        case 'raoSocial':
          this.isFocused.raoSocial = false;
          this.poblacionsDesti = [];
      }
    }, 200);
  }

  async changeContextPoblacio($event: any, type: string) {
    if ($event.target.value.length >= 3) {
      type === 'origen'
        ? (this.poblacionsOrigen = await lastValueFrom(
            this.contextService.getPoblacions(
              $event.target.value,
              this.form.value.codiProvinciaOrigen
            )
          ))
        : (this.poblacionsDesti = await lastValueFrom(
            this.contextService.getPoblacions(
              $event.target.value,
              this.form.value.codiProvinciaDesti
            )
          ));
    }
  }

  setFormValue(campo: string, value: any) {
    switch (campo) {
      case 'poblacioOrigen':
        this.form.patchValue({ poblacioOrigen: value.descripcio });
        this.codiPoblacioOrigen = value.codiElement;
        break;
      case 'poblacioDesti':
        this.form.patchValue({ poblacioDesti: value.descripcio });
        this.codiPoblacioDesti = value.codiElement;
        break;
      case 'nif':
        this.form.patchValue({ nifTransportista: value.nif });
        this.form.patchValue({ raoSocial: value.raoSocial });
        break;
      case 'raoSocial':
        this.form.patchValue({ nifTransportista: value.nif });
        this.form.patchValue({ raoSocial: value.raoSocial });
    }
  }

  errorValidator(error: any) {
    if (error.camp === 'empresa-alias' && error.codiError === 'VE-D02') {
      error.codiError = 'ALIAS';
    }
    if (
      error.camp === 'transportista-carregador' &&
      error.codiError === 'VE-D02'
    ) {
      error.codiError = 'NIF';
    }
    if (error.camp === 'empresa-vehicle' && error.codiError === 'VE-D02') {
      error.codiError = 'MAT';
    }
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: `ERRORS.${error.codiError}`,
        message: error.missatge || '',
        icon: 'warning',
        showCancel: false,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        this.dialog.closeAll();
        this.habitualService.emitHabitualOpenModal(
          this.data.type,
          {
            ...this.form.value,
            codiPoblacioOrigen: this.codiPoblacioOrigen,
            codiPoblacioDesti: this.codiPoblacioDesti,
          },
          this.data.edit,
          this.data.openList
        );
      }
    );
  }

  getProvincia(codi: string) {
    const prov = this.provinces.find((el) => el.value === codi);
    if (!prov) {
      return '';
    }
    return prov.label;
  }

  async closeModal(save: boolean = false) {
    if (save) {
      const requiredFields = [
        { field: 'alias', tooltip: this.aliasTooltipRef },
        { field: 'poblacioOrigen', tooltip: this.poblacioOrigenTooltipRef },
        { field: 'poblacioDesti', tooltip: this.poblacioDestiTooltipRef },
        { field: 'raoSocial', tooltip: this.raoSocialTooltipRef },
        { field: 'nifTransportista', tooltip: this.nifTransportistaTooltipRef },
        { field: 'matricula', tooltip: this.matriculaTooltipRef },
        { field: 'tipusVehicle', tooltip: this.tipusVehicleTooltipRef },
      ];
      this.tooltipText = 'MODAL.OBLIGATORI';

      setTimeout(() => {
        this.tooltipText = '';
      }, 2000);
      for (const { field, tooltip } of requiredFields) {
        if (this.form.get(field)?.invalid) {
          setTimeout(() => {
            tooltip.show();
          }, 100);
          return;
        }
      }

      let response;
      this.form.value.estat = true;

      if (!this.data.data?.objectMantId) {
        switch (this.data.type) {
          case 'ruta':
            response = await lastValueFrom(
              this.rutaService.createRuta({
                ...this.form.value,
                codiPoblacioOrigen: this.codiPoblacioOrigen,
                codiPoblacioDesti: this.codiPoblacioDesti,
              })
            );
            break;
          case 'transportista':
            response = await lastValueFrom(
              this.transportistaService.createTransportista(this.form.value)
            );
            break;
          case 'vehicle':
            response = await lastValueFrom(
              this.vehicleService.createVehicle(this.form.value)
            );
            break;
        }
        if (response?.validacioResultat) {
          this.errorValidator(response.validacioResultat[0]);
          return;
        }
      } else {
        if (this.data.type == 'ruta') {
          response = await lastValueFrom(
            this.rutaService.updateRuta({
              ...this.form.value,
              objectMantId: this.rowdata.objectMantId,
              favorit: this.rowdata.favorit,
              codiPoblacioOrigen: this.codiPoblacioOrigen,
              codiPoblacioDesti: this.codiPoblacioDesti,
              provinciaOrigen: this.getProvincia(
                this.form.value.codiProvinciaOrigen
              ),
              provinciaDesti: this.getProvincia(
                this.form.value.codiProvinciaOrigen
              ),
            })
          );
        }
        if (this.data.type == 'transportista') {
          response = await lastValueFrom(
            this.transportistaService.updateTransportista({
              ...this.form.value,
              objectMantId: this.rowdata.objectMantId,
              favorit: this.rowdata.favorit,
            })
          );
          if (response?.validacioResultat) {
            this.errorValidator(response.validacioResultat[0]);
            return;
          }
        }
        if (this.data.type == 'vehicle') {
          response = await lastValueFrom(
            this.vehicleService.updateVehicle({
              ...this.form.value,
              objectMantId: this.rowdata.objectMantId,
              favorit: this.rowdata.favorit,
            })
          );
        }
        if (response?.validacioResultat) {
          this.errorValidator(response.validacioResultat[0]);
          return;
        }
        this.habitualService.emitFavotit(this.data.type, this.form.value);
      }

      this.habitualService.emitHabitualAdmin();
    }
    this.closeEvent.emit();

    if (this.data.openList) {
      const dialogRef = this.dialog.open(HabitualsListComponent, {
        data: { type: this.data.type },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
      });
    }
  }
}
