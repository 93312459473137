<main>
  <app-sidebar [admin]="true"></app-sidebar>
  <section>
    <div class="trow">
      <div>
        <h1>{{ "INICI.TITLE" | translate }}</h1>
        <p class="sub">{{ "INICI.SUBTITLE" | translate }}</p>
      </div>
      <img
        src="../../../assets/images/Ambos.png"
        width="350"
        height="202"
        class="img-top"
      />
    </div>
    @if(isWeb) {
    <div class="drow">
      @if(carregador) {
      <div class="tarjetaPrimary" (click)="routeTo('carregador')">
        <img src="../../../assets/images/forklift_red.png" />
        <p>{{ "INICI.CARREGADOR" | translate }}</p>
      </div>
      } @if(transportista) {
      <div class="tarjetaPrimary" (click)="routeTo('transportista')">
        <img src="../../../assets/images/local_shipping_red.png" />
        <p>{{ "INICI.TRANSPORTISTA" | translate }}</p>
      </div>
      }

      <div class="col">
        <div class="tarjetaSecondary" (click)="routeTo('perfil')">
          <mat-icon size="66px" class="icon">account_circle</mat-icon>
          <p>{{ "INICI.PERFIL" | translate }}</p>
        </div>
        <div class="tarjetaSecondary" (click)="routeTo('administracio')">
          <mat-icon size="36px" class="icon">settings</mat-icon>
          <p>{{ "INICI.ADMINISTRACIO" | translate }}</p>
        </div>
      </div>

      <img width="350" src="../../../assets/images/Ambos.png" class="image" />
    </div>
    } @else {
    <h4>{{ "INICI.TEUS_STMC" | translate }}</h4>
    <div id="llista-stmc">
      <div class="dates">
        <mat-icon (click)="fletxaDia(-1)">arrow_back_ios</mat-icon>
        <div class="input">
          <label>{{ "INICI.SELECT_DATE" | translate }}</label>
          <input
            type="date"
            class="ctti-input-text enDada"
            [value]="dataHui()"
            (change)="changeDay($event)"
          />
        </div>
        <mat-icon (click)="fletxaDia(1)">arrow_forward_ios</mat-icon>
      </div>
      <table>
        <tr *ngFor="let element of paginatedData" class="stmc-row">
          <td class="codi">
            <h4>{{ element.codiStmc }}</h4>
            <div [class]="element.estatStmc.toLowerCase()">
              {{ textTipus(element.estatStmc) | translate }}
              <div [class]="element.estatDc.toLowerCase()"><mat-icon>{{ iconaTipus(element.estatDc) }}</mat-icon></div>
            </div>
          </td>
          <td>
            <div class="dades-smtc">
              <div class="dada-smtc">
                <div><mat-icon>calendar_today</mat-icon></div>
                <p>{{ element.dataRealitzacio }}</p>
              </div>
              <div class="dada-smtc">
                <div><mat-icon>location_on</mat-icon></div>
                <p>
                  {{ element.poblacioOrigen + " - " + element.poblacioDesti }}
                </p>
              </div>
              <div class="dada-smtc">
                <div><mat-icon>local_shipping</mat-icon></div>
                <p>{{ element.raoSocialTransportista }}</p>
              </div>
              <div class="dada-smtc">
                <div><mat-icon>inventory</mat-icon></div>
                <p>{{ mercaderiaValue(element.tipusMercaderia) }}</p>
              </div>
            </div>
          </td>
          <td>
            <div class="actions">
              <div class="mainAction" (click)="generarDC(element)">
                <mat-icon>task_alt</mat-icon>
                <p class="red">{{ (element.estatDc == "DC_CREAT" ? "CARREGADOR.VISUALITZAR_DC" : "CARREGADOR.GENERAR_DC") | translate }}</p>
              </div>
              <div class="others" [matMenuTriggerFor]="item">
                <mat-icon>more_horiz</mat-icon>
              </div>
              <mat-menu #item="matMenu" yPosition="below">
                <ul class="more">
                  <li
                    (click)="viewElement(element)"
                    *ngIf="
                      [
                        'ESBORRANY',
                        'REBUTJAT',
                        'PLANIFICAT',
                        'DC_CREAT',
                        'CANCELAT',
                        'REALIZAT',
                        'NO_REALIZAT'
                      ].includes(element.estatStmc)
                    "
                  >
                    <mat-icon>visibility</mat-icon>
                    <p>{{'CARREGADOR.VIEW' | translate}}</p>
                  </li>
                  <li
                    (click)="editElement(element.objectMantId)"
                    *ngIf="
                      [
                        'ESBORRANY',
                        'PLANIFICAT',
                        'DC_CREAT',
                        'REBUTJAT'
                      ].includes(element.estatStmc)
                    "
                  >
                    <mat-icon>edit</mat-icon>
                    <p>{{'CARREGADOR.EDIT' | translate}}</p>
                  </li>
                  <li (click)="duplicar(element.codiStmc)">
                    <mat-icon>content_copy</mat-icon>
                    <p>{{'CARREGADOR.DUPLICATE' | translate}}</p>
                  </li>
                </ul>
              </mat-menu>
            </div>
          </td>
        </tr>
      </table>
    </div>
    }
  </section>
</main>
