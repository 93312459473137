<main>
  <app-sidebar [admin]="true"></app-sidebar>
  <section [formGroup]="formData">

    <div class="block">
      <div>
        <h1>{{ "ADMIN.TITLE" | translate }}</h1>
        <p class="sub">{{ "ADMIN.SUBTITLE" | translate }}</p>
        <br /><br />
        <div class="seleccio btn-seccions">
          <div [ngClass]="usuaris ? 'selected' : ''" (click)="setUsuaris(true)">
            <mat-icon>person</mat-icon>{{ "ADMIN.USUARIS" | translate }}
          </div>
          <div [ngClass]="usuaris ? '' : 'selected'" (click)="setUsuaris(false)">
            <mat-icon>favorite</mat-icon>{{ "ADMIN.HABITUALS" | translate }}
          </div>
        </div>
      </div>
      <div class="desktop"></div>
    </div>

    <div class="block">
      <div class="dades">
        @if(usuaris) {
        <h5 class="title5">{{ "ADMIN.CERCA" | translate }}</h5>
        <div class="filtres">
          <div class="grid-inputs">
            <div class="input">
              <label>{{ "ADMIN.NIF" | translate }}:</label>
              <input
                matInput
                type="text"
                id="nif"
                formControlName="dniUsuari"
                placeholder="{{ 'ADMIN.NIF_PLACEHOLDER' | translate }}"
                class="ctti-input-text"
              />
            </div>
            <div class="input item2">
              <label>{{ "ADMIN.NOM" | translate }}:</label>
              <input
                matInput
                type="text"
                id="cognom"
                formControlName="cognom"
                placeholder="{{ 'ADMIN.NOM_PLACEHOLDER' | translate }}"
                class="ctti-input-text"
              />
            </div>
            <div class="input">
              <label>{{ "STMC.STATUS" | translate }}:</label>
              <select
                formControlName="estat"
                class="ctti-dropdown"
                [ngClass]="formData.value.estat ? 'enDada' : ''"
              >
                <option value="" disabled selected>
                  {{ "ADMIN.ESTAT_PLACEHOLDER" | translate }}
                </option>
                <option [value]="true">{{ "ADMIN.ACTIU" | translate }}</option>
                <option [value]="false">{{ "ADMIN.INACTIU" | translate }}</option>
              </select>
            </div>
          </div>
          <div class="grid-inputs-mes">
            @if(mesFiltres) {
            <div class="input">
              <label>{{ "ADMIN.CODI" | translate }}:</label>
              <select
                formControlName="codiPerfil"
                class="ctti-dropdown"
                [ngClass]="formData.value.codiPerfil ? 'enDada' : ''"
              >
                <option value="" disabled selected>
                  {{ "ADMIN.CODI_PLACEHOLDER" | translate }}
                </option>
                <option [value]="false">{{ "ADMIN.GESTOR" | translate }}</option>
                <option [value]="true">
                  {{ "ADMIN.ADMIN" | translate }}
                </option>
              </select>
            </div>
            <div class="input">
              <label>{{ "ADMIN.EMAIL" | translate }}:</label>
              <input
                matInput
                type="text"
                id="adrecaElectronica"
                formControlName="adrecaElectronica"
                placeholder="{{ 'ADMIN.EMAIL_PLACEHOLDER' | translate }}"
                class="ctti-input-text"
              />
            </div>
            }
          </div>
          <div class="botons-filtres">
            <button
              class="ctti-button ctti-button-inv"
              (click)="showMesFiltres()"
            >
              @if(!mesFiltres) {
              <mat-icon>add</mat-icon>
              {{ "CARREGADOR.MES_FILTRES" | translate }}
              } @else {
              <mat-icon>remove</mat-icon>
              {{ "CARREGADOR.MENYS_FILTRES" | translate }}
              }
            </button>
            <div class="accions-filtres">
              <button
                class="ctti-button ctti-button-trans"
                (click)="clearFilter()"
              >
                {{ "CARREGADOR.CLEAR" | translate }}
              </button>
              <button
                class="ctti-button ctti-button-filled"
                (click)="applyFilter()"
              >
                <mat-icon>search</mat-icon>{{ "CARREGADOR.SEARCH" | translate }}
              </button>
            </div>
          </div>
        </div>

        <br />
        @if(this.usuari.codiPerfil == "PROF_ADMIN") {
        <div class="crear tablet mobile">
          <h5 class="title5">{{ "ADMIN.NEW" | translate }}</h5>
          <div class="saveBox">
            <button
              class="desar ctti-button ctti-button-filled"
              (click)="newUsuari()"
            >
              <mat-icon>add</mat-icon>
              {{ "ADMIN.NEW_BTN" | translate }}
            </button>
          </div>
        </div>
        }

        <h5 class="title5">{{ "ADMIN.RESULTATS" | translate }}</h5>
        <div class="stmcs">
          <div class="order">
            <select
              class="ctti-dropdown enDada"
              (change)="sortFieldChange($event)"
            >
              <option value="cognom" (click)="updateSortField('cognom')">
                {{ "ADMIN.NOM_ORD" | translate }}
              </option>
              <option value="dni" (click)="updateSortField('dni')">
                {{ "ADMIN.NIF_ORD" | translate }}
              </option>
              <option value="estat" (click)="updateSortField('estat')">
                {{ "ADMIN.ESTAT_ORD" | translate }}
              </option>
              <option
                value="adrecaElectronica"
                (click)="updateSortField('adrecaElectronica')"
              >
                {{ "ADMIN.ADRECA_ORD" | translate }}
              </option>
              <option value="codiPerfil" (click)="updateSortField('codiPerfil')">
                {{ "ADMIN.CODI_ORD" | translate }}
              </option>
            </select>
            <mat-icon (click)="changeOrder()">{{
              sortOrder ? "arrow_upward" : "arrow_downward"
            }}</mat-icon>
          </div>
          <table>
            <tr *ngFor="let element of dataSource" class="stmc-row">
              <td class="codi">
                <h5 class="title5">{{ element.dniUsuari }}</h5>
                <div class="estat">
                  @if(element.estat) {
                  <div class="actiu"></div>
                  <p>{{ "ADMIN.ACTIU" | translate }}</p>
                  } @else {
                  <div class="inactiu"></div>
                  <p>{{ "ADMIN.INACTIU" | translate }}</p>
                  }
                </div>
              </td>
              <td>
                <div class="dades-smtc">
                  <div class="dada-smtc">
                    <div><mat-icon>person</mat-icon></div>
                    <p>{{ element.nom + " " + element.cognom }}</p>
                  </div>
                  <div class="dada-smtc">
                    <div><mat-icon>smartphone</mat-icon></div>
                    <p>
                      {{ element.telefon }}
                    </p>
                  </div>
                  <div class="dada-smtc">
                    <div><mat-icon>mail</mat-icon></div>
                    <p>{{ element.adrecaElectronica }}</p>
                  </div>
                  <div class="dada-smtc">
                    <div><mat-icon>work</mat-icon></div>
                    <p>{{ adminGestor(element) }}</p>
                  </div>
                </div>
              </td>
              <td>
                <div class="actions">
                  @if(this.usuari.codiPerfil == "PROF_ADMIN" && this.usuari.dniUsuari !== element.dniUsuari) {
                  <p class="red" (click)="editElement(element)">
                    <mat-icon>edit</mat-icon>
                  </p>
                  }
                </div>
              </td>
            </tr>
          </table>

          <div class="pagination-controls">
            <div class="items-page">
              <select id="itemsPerPage" (change)="onItemsPerPageChange($event)">
                <option *ngFor="let size of pageSizeOptions" [value]="size">
                  {{ size }} / {{ "CARREGADOR.PAG" | translate }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGADOR.DE" | translate }} {{ totalRegistres }}
                {{ "CARREGADOR.ELEMENTS" | translate }}
              </p>
            </div>
            <div class="page-number">
              <select [value]="currentPage" (change)="goToPage($event)">
                <option *ngFor="let index of pageNumberArr" [value]="index">
                  {{ index }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGADOR.DE" | translate }} {{ totalPages }}
                {{ "CARREGADOR.PAGINES" | translate }}
              </p>
              @if(currentPage !== 1) {
              <button (click)="previousPage()">
                <mat-icon>chevron_left</mat-icon>
              </button>
              } @if(currentPage !== totalPages) {
              <button (click)="nextPage()">
                <mat-icon>chevron_right</mat-icon>
              </button>
              }
            </div>
          </div>
        </div>
        } @else {
        <h5 class="title5">{{ "ADMIN.EDITA_HABITUALS" | translate }}</h5>
        <div class="saveBox mobile">
          <button
            class="desar ctti-button ctti-button-filled"
            (click)="newHabitual()"
          >
            <mat-icon>add</mat-icon>
            {{ "ADMIN.NEW_BTN_HABITUAL" | translate }}
          </button>
          <br />
        </div>
        <div class="seleccio tipus">
          <div
            [ngClass]="type === 'ruta' ? 'selected' : ''"
            (click)="setHabituals('ruta')"
          >
            {{ "ADMIN.RUTES" | translate }}
          </div>
          @if(this.empresa.rol !== "TRANSPORTISTA") {
          <div
            [ngClass]="type === 'transportista' ? 'selected' : ''"
            (click)="setHabituals('transportista')"
          >
            {{ "ADMIN.TRANSPORTISTES" | translate }}
          </div>
          }
          <div
            [ngClass]="type === 'vehicle' ? 'selected' : ''"
            (click)="setHabituals('vehicle')"
          >
            {{ "ADMIN.VEHICLES" | translate }}
          </div>
        </div>
        <div class="bloc-habituals">
          @if(type === "vehicle") {
          <label>{{ "ADMIN.CERCAR_LABEL_V" | translate }}</label>
          } @if(type === "transportista") {
          <label>{{ "ADMIN.CERCAR_LABEL_T" | translate }}</label>
          } @if(type === "ruta") {
          <label>{{ "ADMIN.CERCAR_LABEL_R" | translate }}</label>
          }
          <form
            class="search-bar"
            (submit)="applyFilter()"
            [formGroup]="formHabituals"
          >
            <div class="w-100">
              <input
                type="search"
                class="ctti-input-text"
                formControlName="text"
                (keyup)="applyFilter()"
                placeholder="Cercar..."
              />
            </div>
          </form>
          <p>{{ "ADMIN.CERCAR_SUB" | translate }}</p>

          <div class="crear tablet noMobile">
            <h5 class="title5">{{ "ADMIN.NEW_HABITUAL" | translate }}</h5>
            <div class="saveBox">
              <button
                class="desar ctti-button ctti-button-filled"
                (click)="newHabitual()"
              >
                <mat-icon>add</mat-icon>
                {{ "ADMIN.NEW_BTN_HABITUAL" | translate }}
              </button>
            </div>
          </div>

          <table class="ctti-table noMobile">
            <tbody>
              <tr *ngFor="let element of dataSource">
                <td>
                  <div class="habitual">
                    {{ element.alias }}
                  </div>
                </td>
                @if(type == 'ruta') {
                <td>
                  <div class="dataTaulaHabitual">
                    <label>{{ "CARREGADOR.ORIGEN" | translate }}</label>
                    <p>{{ element.poblacioOrigen }}</p>
                  </div>
                </td>
                <td>
                  <div class="dataTaulaHabitual">
                    <label>{{ "CARREGADOR.DESTI" | translate }}</label>
                    <p>{{ element.poblacioDesti }}</p>
                  </div>
                </td>
                } @if(type == 'transportista') {
                <td>
                  <div class="dataTaulaHabitual">
                    <label>{{ "STMC.RAO_SOCIAL" | translate }}</label>
                    <p>{{ element.raoSocial }}</p>
                  </div>
                </td>
                <td>
                  <div class="dataTaulaHabitual">
                    <label>{{ "STMC.COMPANY_NIF" | translate }}</label>
                    <p>{{ element.nifTransportista }}</p>
                  </div>
                </td>
                } @if(type == 'vehicle'){
                <td>
                  <div class="dataTaulaHabitual">
                    <label>{{ "STMC.LICENSE_PLATE" | translate }}</label>
                    <p>{{ element.matricula }}</p>
                  </div>
                </td>
                <td>
                  <div class="dataTaulaHabitual">
                    <label>{{ "STMC.DESCRIPCIO" | translate }}</label>
                    <p>{{ element.observacions }}</p>
                  </div>
                </td>
                <td>
                  <div class="dataTaulaHabitual">
                    <label>{{ "STMC.TIPUS_VEHICLE" | translate }}</label>
                    <p>{{ element.tipusVehicle }}</p>
                  </div>
                </td>
                }
                <td>
                  <div class="actions">
                    <img
                      (click)="setFavorit(element)"
                      class="corazon"
                      [src]="
                        element.favorit
                          ? '../../../assets/images/favorite_fill.png'
                          : '../../../assets/images/favorite.png'
                      "
                    />
                    @if(type == 'ruta') {
                    <p class="red" (click)="editHabitual(element, false)">
                      <mat-icon>visibility</mat-icon>
                    </p>
                    }
                    <p class="red" (click)="editHabitual(element, true)">
                      <mat-icon>edit</mat-icon>
                    </p>
                    <p class="red" (click)="deleteHabitual(element)">
                      <mat-icon>delete</mat-icon>
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="mobile">
            <div class="habituals-list">
              @for(item of dataSource; track item) {
              <div class="habitual-item">
                <div class="habitual">
                  {{ item.alias }}
                </div>
                @if(type == 'transportista') {
                <div>
                  <label>{{ "STMC.RAO_SOCIAL" | translate }}</label>
                  <p>{{ item.raoSocial }}</p>
                </div>
                <div>
                  <label>{{ "STMC.COMPANY_NIF" | translate }}</label>
                  <p>{{ item.nifTransportista }}</p>
                </div>
                } @if(type == 'ruta') {
                <div>
                  <label>{{ "CARREGADOR.ORIGEN" | translate }}</label>
                  <p>{{ item.poblacioOrigen }}</p>
                </div>
                <div>
                  <label>{{ "CARREGADOR.DESTI" | translate }}</label>
                  <p>{{ item.poblacioDesti }}</p>
                </div>

                } @if(type== 'vehicle') {
                <div>
                  <label>{{ "STMC.LICENSE_PLATE" | translate }}</label>
                  <p>{{ item.matricula }}</p>
                </div>
                <div>
                  <label>{{ "STMC.TIPUS_VEHICLE" | translate }}</label>
                  <p>{{ item.tipusVehicle }}</p>
                </div>
                <div>
                  <label>{{ "STMC.DESCRIPCIO" | translate }}</label>
                  <p>{{ item.observacions }}</p>
                </div>
                }
                <div class="actions">
                  <img
                    (click)="setFavorit(item)"
                    class="corazon"
                    [src]="
                      item.favorit
                        ? '../../../assets/images/favorite_fill.png'
                        : '../../../assets/images/favorite.png'
                    "
                  />
                  @if(type == 'ruta') {
                  <p class="red" (click)="editHabitual(item, false)">
                    <mat-icon>visibility</mat-icon>
                  </p>
                  }
                  <p class="red" (click)="editHabitual(item, true)">
                    <mat-icon>edit</mat-icon>
                  </p>
                  <p class="red" (click)="deleteHabitual(item)">
                    <mat-icon>delete</mat-icon>
                  </p>
                </div>
              </div>
              }
              <div class="pagination-controls">
                <div class="items-page">
                  <select
                    id="itemsPerPage"
                    (change)="onItemsPerPageChange($event)"
                  >
                    <option *ngFor="let size of pageSizeOptions" [value]="size">
                      {{ size }} / {{ "CARREGADOR.PAG" | translate }}
                    </option>
                  </select>
                  <p class="paginacio">
                    {{ "CARREGADOR.DE" | translate }} {{ totalRegistres }}
                    {{ "CARREGADOR.ELEMENTS" | translate }}
                  </p>
                </div>
                <div class="page-number">
                  <select [value]="currentPage" (change)="goToPage($event)">
                    <option *ngFor="let index of pageNumberArr" [value]="index">
                      {{ index }}
                    </option>
                  </select>
                  <p class="paginacio">
                    {{ "CARREGADOR.DE" | translate }} {{ totalPages }}
                    {{ "CARREGADOR.PAGINES" | translate }}
                  </p>
                  @if(currentPage !== 1) {
                  <button (click)="previousPage()">
                    <mat-icon>chevron_left</mat-icon>
                  </button>
                  } @if(currentPage !== totalPages) {
                  <button (click)="nextPage()">
                    <mat-icon>chevron_right</mat-icon>
                  </button>
                  }
                </div>
              </div>
            </div>
          </div>

          <div class="pagination-controls noMobile">
            <div class="items-page">
              <select id="itemsPerPage" (change)="onItemsPerPageChange($event)">
                <option *ngFor="let size of pageSizeOptions" [value]="size">
                  {{ size }} / {{ "CARREGADOR.PAG" | translate }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGADOR.DE" | translate }} {{ totalRegistres }}
                {{ "CARREGADOR.ELEMENTS" | translate }}
              </p>
            </div>
            <div class="page-number">
              <select [value]="currentPage" (change)="goToPage($event)">
                <option *ngFor="let index of pageNumberArr" [value]="index">
                  {{ index }}
                </option>
              </select>
              <p class="paginacio">
                {{ "CARREGADOR.DE" | translate }} {{ totalPages }}
                {{ "CARREGADOR.PAGINES" | translate }}
              </p>
              @if(currentPage !== 1) {
              <button (click)="previousPage()">
                <mat-icon>chevron_left</mat-icon>
              </button>
              } @if(currentPage !== totalPages) {
              <button (click)="nextPage()">
                <mat-icon>chevron_right</mat-icon>
              </button>
              }
            </div>
          </div>
        </div>
        }
      </div>

      @if(usuaris) { 
        @if(this.usuari.codiPerfil == "PROF_ADMIN") {
        <div class="crear desktop">
          <h5>{{ "ADMIN.NEW" | translate }}</h5>
          <div class="saveBox">
            <button
              class="desar ctti-button ctti-button-filled"
              (click)="newUsuari()"
            >
              <mat-icon>add</mat-icon>
              {{ "ADMIN.NEW_BTN" | translate }}
            </button>
          </div>
        </div>
        } 
      } 
      @else {
      <div class="crearHabitual desktop">
        <h5>{{ "ADMIN.NEW_HABITUAL" | translate }}</h5>
        <div class="saveBox">
          <button
            class="desar ctti-button ctti-button-filled"
            (click)="newHabitual()"
          >
            <mat-icon>add</mat-icon>
            {{ "ADMIN.NEW_BTN_HABITUAL" | translate }}
          </button>
        </div>
      </div>
      }
    </div>
  </section>
</main>
