import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Usuari } from '../../types/Usuari';
import { environment } from '../../../environments/environment'
import { HttpClient } from '@angular/common/http';
import { Empresa } from '../../types/Empresa';

interface Response {
  dadesResultat: Usuari
  validacioResultat: any
}
interface ResponseEmpresa {
  dadesResultat: Empresa
  validacioResultat: any
}
interface ResponseImage {
  dadesResultat: {usuariFitxer64: string, nomFitxer: string}
  validacioResultat: any
}
interface ResponseMultiple {
  dadesResultat: Usuari[]
  validacioResultat: any,
  dadesPaginacio: any;
}

@Injectable({
  providedIn: 'root'
})
export class UsuariService {
  private apiUrl: string = `${environment.apiUrl}usuari`;
  private apiUrlProfesional: string = `${environment.apiUrl}professional`;
  
  private saveUser = new Subject<{ data: Usuari, id: string | null }>;
  saveUser$ = this.saveUser.asObservable();

  constructor(private http: HttpClient) {}

  //USUARIS PROFESSIONALS

  getProfesionalById(id: number): Observable<Response> {
    const url = `${this.apiUrlProfesional}/${id}`;
    return this.http.get<Response>(url);
  }

  createProfessional(usuari: Usuari): Observable<Response> {
    return this.http.post<Response>(this.apiUrlProfesional, usuari);
  }

  profesionalsFindBy(filters: any): Observable<ResponseMultiple> {
    const url = `${this.apiUrlProfesional}/findBy`;
    return this.http.post<ResponseMultiple>(url, filters);
  }

  updateProfesional(usuari: Usuari, idEmpresaUsuari: string): Observable<Response> {
    const url = `${this.apiUrlProfesional}/${idEmpresaUsuari}`;
    return this.http.put<Response>(url, usuari);
  }

  deleteUsuari(idEmpresaUsuari: number): Observable<void> {
    const url = `${this.apiUrlProfesional}/${idEmpresaUsuari}`;
    return this.http.delete<void>(url);
  }

  //REGISTRAR

  registraUsuari(usuari: Usuari, idEmpresaUsuari: string) {
    const url = `${this.apiUrlProfesional}/${idEmpresaUsuari}/registrarUsuari`;
    return this.http.put<Response>(url, usuari);
  }
  registraEmpresa(empresa: any, idEmpresa: string) {
    const url = `${this.apiUrlProfesional}/${idEmpresa}/registrarEmpresa`;
    return this.http.put<ResponseEmpresa>(url, empresa);
  }

  //IMAGEN PERFIL

  getImage(nomFitxer: string, id: string) {
    const url = `${this.apiUrlProfesional}/${id}/descarregarImatge`; //${nomFitxer}
    return this.http.get<ResponseImage>(url);
  }
  setImage(id: string, fitxer: File) {
    const formData = new FormData();
    formData.append('fitxer', fitxer);
    const url = `${this.apiUrlProfesional}/${id}/pujarImatge`;
    return this.http.post<Response>(url, formData);
  }

  //EVENT

  emitSaveUser(data: Usuari, id: string | null) {
    this.saveUser.next({data, id});
  }
}