import { Component, Inject, EventEmitter, Output, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Transportista } from '../../types/Transportista';
import { Ruta } from '../../types/Ruta';
import { TransportistaService } from '../../services/api/transportista.service';
import { RutaService } from '../../services/api/ruta.service';
import { lastValueFrom } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { VehicleService } from '../../services/api/vehicle.service';
import { Vehicle } from '../../types/Vehicle';

type MitjaTransport = {
  matriculaTractor: string;
  matriculaRemolc: string;
  matriculaTractorSubs1: string;
  matriculaRemolcSubs1: string;
  matriculaTractorSubs2?: string;
  matriculaRemolcSubs2?: string;
};

@Component({
  selector: 'app-matricules',
  template: `
    <div class="ctti-modal text-left" [formGroup]="form">
      <div class="cross" (click)="closeModal()">
        <mat-icon> close </mat-icon>
      </div>
      <h5>{{ 'MODAL.MATRICULES' | translate }}</h5>
      <p>{{ 'MODAL.MATRICULES_SUB' | translate }}</p>
      <br />
      <div class="grid-2">
        <div class="input">
          <label for="matriculaTractorSubs1"
            >{{ 'STMC.SUBVEHICLE_LICENSE_PLATE' | translate }} 1:</label
          >
          <input
            type="text"
            id="matriculaTractorSubs1"
            class="ctti-input-text"
            formControlName="matriculaTractorSubs1"
            placeholder="{{
              'STMC.SUBVEHICLE_LICENSE_PLATE_PLACEHOLDER' | translate
            }}"
          />
        </div>
        <div class="input">
          <label>{{ 'STMC.SUBVEHICLE_LICENSE_PLATE' | translate }} 2:</label>
          <input
            type="text"
            id="matriculaTractorSubs2"
            class="ctti-input-text"
            formControlName="matriculaTractorSubs2"
            placeholder="{{
              'STMC.SUBVEHICLE_LICENSE_PLATE_PLACEHOLDER' | translate
            }}"
          />
        </div>
        <div class="input">
          <label for="matriculaRemolcSubs1"
            >{{ 'STMC.SUBTRAILER_LICENSE_PLATE' | translate }} 1:</label
          >
          <input
            type="text"
            id="matriculaRemolcSubs1"
            class="ctti-input-text"
            formControlName="matriculaRemolcSubs1"
            placeholder="{{
              'STMC.SUBTRAILER_LICENSE_PLATE_PLACEHOLDER' | translate
            }}"
          />
        </div>
        <div class="input">
          <label>{{ 'STMC.SUBTRAILER_LICENSE_PLATE' | translate }} 2:</label>
          <input
            type="text"
            id="matriculaRemolcSubs2"
            class="ctti-input-text"
            formControlName="matriculaRemolcSubs2"
            placeholder="{{
              'STMC.SUBTRAILER_LICENSE_PLATE_PLACEHOLDER' | translate
            }}"
          />
        </div>
      </div>
      <br />
      <div class="botones">
        <button
          class="ctti-button ctti-button-trans"
          mat-dialog-close
          (click)="closeModal()"
        >
          {{ 'MODAL.CANCEL' | translate }}
        </button>
        <button
          class="ctti-button ctti-button-filled"
          mat-dialog-close
          (click)="closeModal(true)"
        >
          {{ 'MODAL.SAVE' | translate }}
        </button>
      </div>
    </div>
  `,
})
export class MatriculesComponent {
  @Output() closeEvent = new EventEmitter<{
    mitjaTransport: MitjaTransport;
    accept: boolean;
  }>();
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { mitjaTransport: MitjaTransport },
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.form = this.fb.group({
      matriculaTractorSubs1: '',
      matriculaRemolcSubs1: '',
      matriculaTractorSubs2: '',
      matriculaRemolcSubs2: '',
    });
  }

  ngOnInit() {
    this.form.patchValue(this.data.mitjaTransport);
  }

  async closeModal(save: boolean = false) {
    this.data.mitjaTransport = {
        matriculaTractor: this.data.mitjaTransport.matriculaTractor,
        matriculaRemolc: this.data.mitjaTransport.matriculaRemolc,
        matriculaTractorSubs1: this.form.value.matriculaTractorSubs1,
        matriculaRemolcSubs1: this.form.value.matriculaRemolcSubs1,
        matriculaTractorSubs2: this.form.value.matriculaTractorSubs2,
        matriculaRemolcSubs2: this.form.value.matriculaRemolcSubs2,
    }
    this.closeEvent.emit({ mitjaTransport: this.data.mitjaTransport, accept: save });
  }
}
