import { Component, inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Stmc } from '../../types/Stmc';
import { Router } from '@angular/router';
import { ViewStmcComponent } from '../../shared/view-stmc/view-stmc.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { StmcService } from '../../services/api/stmc.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastComponent } from '../../shared/toast/toast.component';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { ContextService } from '../../services/api/context.service';
import { UsuariService } from '../../services/api/usuari.service';

@Component({
  selector: 'app-transportista',
  templateUrl: './transportista.component.html',
  styleUrl: './transportista.component.scss',
})
export class TransportistaComponent {
  @ViewChild(ToastComponent) toast!: ToastComponent;
  translate = inject(TranslateService);
  stmcService = inject(StmcService);
  contextService = inject(ContextService);
  usuariService = inject(UsuariService);
  tooltipText: string = '';
  poblacions: any[] = [];
  dataSource: Stmc[] = [];
  paginatedData: Stmc[] = [];
  pageNumberArr: number[] = [];
  pageSizeOptions: number[] = [5, 10, 20];
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalPages: number = 1;
  mostrarEstats: boolean = false;
  mesFiltres: boolean = false;
  tipusSelected: number = 1;
  mercaderies: any[] = [];
  estats = [
    'ESBORRANY',
    'PLANIFICAT',
    'REALIZAT',
    'NO_REALIZAT',
    'REBUTJAT',
    'CANCELAT',
  ];
  estatsDc = ['DC_CREAT', 'DC_NO_CREAT', 'DC_MODIFICACIO'];
  estatsDcSelected: string[] = this.estatsDc;
  estatsSelected: string[] = this.estats;
  sortField: string = 'estatStmc';
  sortOrder: boolean = true;
  formData: FormGroup = this.fb.group({
    codiStmc: [''],
    nifRaoCarregador: [''],
    dataRealitzacioInici: [''],
    dataRealitzacioFi: [''],
    matriculaVehicle: [''],
    tipusMercaderia: [''],
    poblacioOrigen: [''],
    poblacioDesti: [''],
    pagina: [''],
    estatStmc: [[]],
    estatDc: [[]],
  });
  isFocused = { poblacioOrigen: false, poblacioDesti: false };
  codiPoblacioOrigen = '';
  codiPoblacioDesti = '';

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.translate.get('CARREGADOR.SUBTITLE').subscribe((res: string) => {
      this.tooltipText = res;
    });
  }

  async ngOnInit() {
    await this.fetchData({});
    this.mercaderies = await lastValueFrom(
      this.contextService.getTotsMercaderies()
    );
  }

  async fetchData(filters: any = {}) {
    if (this.currentPage == 0) {
      this.currentPage = 1;
    }
    Object.keys(filters).forEach((key) => {
      if (filters[key] === '') {
        delete filters[key];
      }
    });
    if (filters.dataRealitzacioFi) {
      const date = filters.dataRealitzacioFi.split('-');
      filters.dataRealitzacioFi =
        date[0].length === 4
          ? `${date[2]}-${date[1]}-${date[0]}`
          : `${date[0]}-${date[1]}-${date[2]}`;
    }
    if (filters.dataRealitzacioInici) {
      const date = filters.dataRealitzacioInici.split('-');
      filters.dataRealitzacioInici =
        date[0].length === 4
          ? `${date[2]}-${date[1]}-${date[0]}`
          : `${date[0]}-${date[1]}-${date[2]}`;
    }
    const data = await lastValueFrom(
      this.stmcService.stmcFindBy({
        dadesPaginacio: {
          numRegisters: this.itemsPerPage,
          numPagina: this.currentPage,
          ordreCamp: this.sortField,
          ordreAsc: this.sortOrder,
        },
        filtresConsulta: {
          ...filters,
          estat: true,
          rolSeleccionat: 'TRANSPORTISTA',
        },
      })
    );
    this.dataSource = data.dadesResultat;

    this.totalPages = Math.ceil(
      data.dadesPaginacio.totalRegisters / this.itemsPerPage
    );
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    this.pageNumberArr = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
  }

  editElement(codi: string) {
    this.router.navigate(['/transportista/stmc/'.concat(codi)]);
  }

  viewElement(element: Stmc) {
    const dialogRef = this.dialog.open(ViewStmcComponent, {
      data: { stmc: element, pag: 'transportista' },
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  async duplicar(codiStmc: string) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'MODAL.DUPLICAR',
        icon: 'help',
        showCancel: true,
        message: 'STMC '.concat(codiStmc),
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: true }) => {
        if (result.accept) {
          const duplicat = await lastValueFrom(
            this.stmcService.duplicar(codiStmc)
          );
          if (duplicat.dadesResultat.codiStmc) {
            const dialogRef = this.dialog.open(ErrorModalComponent, {
              data: {
                title: 'STMC duplicat amb codi '.concat(
                  duplicat.dadesResultat.codiStmc
                ),
                icon: 'check',
                showCancel: false,
                message: '',
              },
            });
            dialogRef.componentInstance.closeEvent.subscribe(() => {
              this.dialog.closeAll();
            });
          }
          await this.fetchData(this.formData.value);
        }
        this.dialog.closeAll();
      }
    );
  }

  generarDC(element: Stmc) {
    if (element.estatDc === "DC_CREAT") {
      window.location.href = `./#/transportista/dc/${element.objectMantId}/generat`;
      return;
    }
    window.location.href = `./#/transportista/dc/${element.objectMantId}/previsualitzacio`;
  }

  async reasignar(element: Stmc) {
    const callback = async (element: Stmc) => {
      const reasignat = await lastValueFrom(
        this.stmcService.canviarEstat(element, 'reassignar')
      );
      const empresa = JSON.parse(localStorage.getItem('empresa') || '{}');
      let reload = false;
      if (!empresa.rol.includes('CARR')) {
        empresa.rol = 'CARRTRANSP';
        localStorage.setItem('empresa', JSON.stringify(empresa));
        await lastValueFrom(
          this.usuariService.registraEmpresa(empresa, empresa.idEmpresa)
        );
        reload = true;
      }
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: `S'ha creat el STMC ${reasignat.dadesResultat.codiStmc} per a gestionar la reassignació.`,
          icon: 'check',
          showCancel: false,
          message: 'Podràs accedir a aquest SMTC des de les opcions de carregador.',
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
        if (reload) {
          window.location.reload();
        }
      });
      await this.fetchData(this.formData.value);
    };

    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: `Es va a reassignar l'STMC ${element.codiStmc}`,
        icon: 'warning',
        showCancel: true,
        message: '¿Continuar?',
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        if (result.accept) {
          callback(element);
        }
        this.dialog.closeAll();
      }
    );
  }

  async rebutjar(element: Stmc) {
    if(!element.nifCarregador){
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: {
          title: `MODAL.NO_CARREGADOR`,
          icon: 'warning',
          showCancel: true,
          message: '',
        },
      });
      dialogRef.componentInstance.closeEvent.subscribe(
        async () => {
          this.dialog.closeAll();
        }
      );
      return;
    }
    
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: `Es va a rebutjar l'STMC amb codi ${element.codiStmc}`,
        icon: 'warning',
        showCancel: true,
        message: '',
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        if (result.accept) {
          await lastValueFrom(this.stmcService.canviarEstat(element, 'rebutjar'));
          this.fetchData(this.formData.value);
        }
        this.dialog.closeAll();
      }
    );
  }

  async cancelar(element: Stmc) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: `Es va a cancelar l'STMC amb codi ${element.codiStmc}`,
        icon: 'warning',
        showCancel: true,
        message: '',
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        if (result.accept) {
          await lastValueFrom(this.stmcService.canviarEstat(element, 'cancelar'));
          this.fetchData(this.formData.value);
        }
        this.dialog.closeAll();
      }
    );
  }

  async realitzar(element: Stmc) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: `Es va a marcar com realitzat l'STMC amb codi ${element.codiStmc}`,
        icon: 'warning',
        showCancel: true,
        message: '',
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        if (result.accept) {
          await lastValueFrom(this.stmcService.canviarEstat(element, 'realitzat'));
          this.fetchData(this.formData.value);
        }
        this.dialog.closeAll();
      }
    );
  }


  newStmc() {
    this.router.navigate(['/transportista/stmc']);
  }

  iconaTipus(tipus: string) {
    switch (tipus) {
      case 'DC_MODIFICACIO':
        return 'sd_card_alert';
      case 'DC_CREAT':
        return 'task';
      case 'DC_NO_CREAT':
        return 'error_outline';
      default:
        return 'error_outline';
    }
  }

  textTipus(value: string) {
    if (!value) {
      return '';
    }
    return `CARREGADOR.${value}`;
  }

  onFocus(campo: string) {
    switch (campo) {
      case 'poblacioOrigen':
        this.isFocused.poblacioOrigen = true;
        break;
      case 'poblacioDesti':
        this.isFocused.poblacioDesti = true;
        break;
    }
  }

  onBlur(campo: string) {
    setTimeout(() => {
      switch (campo) {
        case 'poblacioOrigen':
          this.isFocused.poblacioOrigen = false;
          this.poblacions = [];
          break;
        case 'poblacioDesti':
          this.isFocused.poblacioDesti = false;
          this.poblacions = [];
          break;
      }
    }, 200);
  }

  setFormValue(campo: string, value: string, codi: string = '') {
    switch (campo) {
      case 'poblacioOrigen':
        this.formData.patchValue({ poblacioOrigen: value });
        this.codiPoblacioOrigen = codi;
        break;
      case 'poblacioDesti':
        this.formData.patchValue({ poblacioDesti: value });
        this.codiPoblacioDesti = codi;
    }
  }

  async changeContextPoblacio($event: any) {
    if ($event.target.value.length >= 3) {
      this.poblacions = await lastValueFrom(
        this.contextService.getPoblacions($event.target.value)
      );
    }
  }

  async localitazcions(element: Stmc) {
    const esSoloNumeros = (str: string) => /^\d+$/.test(str);
    let origen = '',
      desti = '';
    if (element.poblacioOrigen && esSoloNumeros(element.poblacioOrigen)) {
      const resp = await lastValueFrom(
        this.contextService.getPoblacions(element.poblacioOrigen)
      );
      if (resp.length) origen = resp[0].descripcio;
    }
    if (element.poblacioDesti && esSoloNumeros(element.poblacioDesti)) {
      const resp = await lastValueFrom(
        this.contextService.getPoblacions(element.poblacioDesti)
      );
      if (resp.length) desti = resp[0].descripcio;
    }
    return [origen, desti];
  }

  mercaderiaValue(value: any) {
    const item = this.mercaderies.find((el) => el.codiElement === value);
    return item ? item.descripcio : '';
  }

  async deleteElement(element: any) {
    this.dataSource = this.dataSource.filter((e) => e !== element);
    await this.fetchData(this.formData.value);
  }

  async clearFilter() {
    this.formData.patchValue({
      codiStmc: '',
      nifRaoCarregador: '',
      dataRealitzacioInici: '',
      dataRealitzacioFi: '',
      matriculaVehicle: '',
      tipusMercaderia: '',
      poblacioOrigen: '',
      poblacioDesti: '',
      estatStmc: [],
      estatDc: [],
    });
    this.estatsSelected = this.estats;
    this.estatsDcSelected = this.estatsDc;
    this.currentPage = 1;
    await this.fetchData();
  }

  showEstats() {
    this.mostrarEstats = !this.mostrarEstats;
  }
  showMesFiltres() {
    this.mesFiltres = !this.mesFiltres;
  }

  async sortFieldChange(event: any) {
    this.sortField = event.target.value;
    await this.updateSortField(this.sortField);
  }

  async onItemsPerPageChange(event: Event) {
    this.currentPage = 1;
    this.itemsPerPage = +(event.target as HTMLSelectElement).value;
    await this.fetchData(this.formData.value);
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.fetchData(this.formData.value);
    }
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.fetchData(this.formData.value);
    }
  }

  goToPage(event: Event) {
    this.currentPage = +(event.target as HTMLSelectElement).value;
    this.fetchData(this.formData.value);
  }

  getValueByField(obj: any, field: string): any {
    return field.split('.').reduce((o, key) => (o ? o[key] : null), obj);
  }

  async updateSortField(field: string) {
    this.currentPage = 1;
    if (this.sortField == field) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortField = field;
    }
    await this.fetchData(this.formData.value);
  }

  async changeOrder() {
    this.currentPage = 1;
    this.sortOrder = !this.sortOrder;
    await this.fetchData(this.formData.value);
  }
}
