import { Component, inject } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ViewStmcComponent } from '../../shared/view-stmc/view-stmc.component';
import { lastValueFrom } from 'rxjs';
import { StmcService } from '../../services/api/stmc.service';
import { MatDialog } from '@angular/material/dialog';
import { Stmc } from '../../types/Stmc';
import { TranslateService } from '@ngx-translate/core';
import { ContextService } from '../../services/api/context.service';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';

@Component({
  selector: 'app-inici',
  templateUrl: './inici.component.html',
  styleUrl: './inici.component.scss'
})
export class IniciComponent {
  translate = inject(TranslateService)
  contextService = inject(ContextService);
  isWeb: boolean = false;
  transportista: boolean = false;
  carregador: boolean = false;
  breakpointObserver = inject(BreakpointObserver)
  stmcService = inject(StmcService);
  paginatedData: Stmc[] = []
  tooltipText: string = ""
  dataSelected: Date = new Date();
  mercaderies: any[] = [];

  constructor(private router: Router, private dialog: MatDialog) {
    this.translate.get('CARREGADOR.SUBTITLE').subscribe((res: string) => {
      this.tooltipText = res;
    });
  }

  async ngOnInit() {
    const empresa = JSON.parse(localStorage.getItem("empresa") || "{}");
    if(empresa.rol) {
      this.carregador = empresa.rol === "CARRTRANSP" || empresa.rol === "CARREGADOR"
      this.transportista = empresa.rol === "CARRTRANSP" || empresa.rol === "TRANSPORTISTA"
    }
    this.breakpointObserver.observe(['(min-width: 916px)']).subscribe(async (state: BreakpointState) => {
      if (state.matches) {
        this.isWeb = true
      }else {
        this.mercaderies = await lastValueFrom(this.contextService.getTotsMercaderies())
        this.fetchData()
      }
    });
  }

  fletxaDia(num: number) {
    this.dataSelected.setDate(this.dataSelected.getDate()+num);
    this.fetchData();
  }

  async changeDay($event: any) {
    this.dataSelected = new Date($event.target.value);
    await this.fetchData()
  }

  mercaderiaValue(value: any) {
    const item =  this.mercaderies.find(el => el.codiElement === value);
    return item ? item.descripcio : "";
  }

  async fetchData() {
    const dataSplitted = this.dataHui().split("-");
    const dataStmcs =  `${dataSplitted[2]}-${dataSplitted[1]}-${dataSplitted[0]}`
    const data = await lastValueFrom(this.stmcService.stmcFindBy({
      "dadesPaginacio": {
        "numRegisters": 15,
        "numPagina": 1,
        "ordreCamp": "codiStmc",
        "ordreAsc": true
      },
      "filtresConsulta": {
        "estat": true,
        "dataRealitzacioInici": dataStmcs,
        "dataRealitzacioFi":  dataStmcs,
        "rolSeleccionat": "TRANSPORTISTA"
      }
    })) 
    this.paginatedData = data.dadesResultat;
  }

  dataHui = () => {
    return this.dataSelected.toISOString().split('T')[0]
  };

  routeTo(ruta: string) {
    this.router.navigate([`/${ruta}`]);
  }

  editElement(codi: string) {
    this.router.navigate(['/carregador/stmc/'.concat(codi)]);
  }

  viewElement(element: Stmc) {
    const dialogRef = this.dialog.open(ViewStmcComponent, {
      data: { stmc: element, pag: 'carregador' },
    });
    dialogRef.componentInstance.closeEvent.subscribe(() => {
      this.dialog.closeAll();
    });
  }

  async generarDC(element: Stmc) {
    const rol = this.transportista ? "transportista" : "carregador"
    if(element.estatDc === "DC_CREAT") {
      window.location.href = `./#/${rol}/dc/${element.objectMantId}/generat`;
      return;
    }
    window.location.href = `./#/${rol}/dc/${element.objectMantId}/previsualitzacio`;
  }

  async reasignar(element: Stmc) {
    const callback = async (element: Stmc) => {
      const reasignat = await lastValueFrom(this.stmcService.canviarEstat(element, "reassignar"));
      const dialogRef = this.dialog.open(ErrorModalComponent, {
        data: { title: `S'ha creat el STMC ${reasignat.dadesResultat.codiStmc} per a gestionar la reassignació.`, icon: "check", showCancel: false, message: "Podràs accedir a aquest SMTC des de les opcions de carregador." }
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
      });
      await this.fetchData();
    }

    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: { title: `Es va a reassignar l'STMC ${element.codiStmc}`, icon: "warning", showCancel: true, message: "¿Continuar?" },
    });
    dialogRef.componentInstance.closeEvent.subscribe(async (result: { accept: boolean }) =>  {
      if(result.accept) {
        callback(element);
      }
      this.dialog.closeAll();
    });
  }

  async rebutjar(element: Stmc) {
    await lastValueFrom(this.stmcService.canviarEstat(element, "rebutjar"))
    this.fetchData();
  }

  async duplicar(codiStmc: string) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: { title: "MODAL.DUPLICAR", icon: "help", showCancel: true, message: "STMC ".concat(codiStmc) }
    });
    dialogRef.componentInstance.closeEvent.subscribe(async (result: {accept: true}) => {
      if(result.accept) {
        const duplicat = await lastValueFrom(this.stmcService.duplicar(codiStmc));
        if(duplicat.dadesResultat.codiStmc) {
          const dialogRef = this.dialog.open(ErrorModalComponent, {
            data: { title: "STMC duplicat amb codi ".concat(duplicat.dadesResultat.codiStmc), icon: "check", showCancel: false, message: "" }
          });
          dialogRef.componentInstance.closeEvent.subscribe(() => {
            this.dialog.closeAll();
          });
        }
        await this.fetchData()
      }
      this.dialog.closeAll();
    });
  }

  copyCode(codi: string) {
    navigator.clipboard.writeText(codi);
  }

  textTipus(text: string) {
    return `CARREGADOR.${text}`;
  }


  iconaTipus(tipus: string) {
    switch (tipus) {
      case 'DC_MODIFICACIO':
        return 'sd_card_alert';
      case 'DC_CREAT':
        return 'task';
      case 'DC_NO_CREAT':
        return 'error_outline';
      default:
        return 'error_outline'
    }
  }
}
