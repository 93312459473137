import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Usuari } from '../../types/Usuari';
import { HabitualsEditComponent } from '../../shared/habituals/habitualsEdit.component';
import { MatDialog } from '@angular/material/dialog';
import { Ruta } from '../../types/Ruta';
import { Transportista } from '../../types/Transportista';
import { ErrorModalComponent } from '../../shared/error-modal/error-modal.component';
import { TransportistaService } from '../../services/api/transportista.service';
import { RutaService } from '../../services/api/ruta.service';
import { last, lastValueFrom, Subscription } from 'rxjs';
import { VehicleService } from '../../services/api/vehicle.service';
import { UsuariService } from '../../services/api/usuari.service';
import { Vehicle } from '../../types/Vehicle';
import { UserEditComponent } from '../../shared/user/user.component';
import { Empresa } from '../../types/Empresa';
import { HabitualService } from '../../services/habitual.service';

interface ResponseMultiple {
  dadesResultat: Usuari[] | Ruta[] | Vehicle[] | Transportista[]
  validacioResultat: any,
  dadesPaginacio: any;
}

@Component({
  selector: 'app-administracio',
  templateUrl: './administracio.component.html',
  styleUrl: './administracio.component.scss'
})
export class AdministracioComponent {
  translate = inject(TranslateService)
  rutaService = inject(RutaService);
  transportistaService = inject(TransportistaService);
  vehiclesService = inject(VehicleService);
  userService = inject(UsuariService);
  habitualService = inject(HabitualService)
  
  tooltipText: string = ""
  dataSource: any[] = [];
  pageNumberArr: number[] = [];
  pageSizeOptions: number[] = [5, 10, 20];
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalPages: number = 1;
  mesFiltres: boolean = false;
  sortField: string = 'cognom';
  sortOrder: boolean = true;
  usuaris: boolean = true;
  usuari!: Usuari;
  empresa!: Empresa;
  type: string = "ruta"
  totalRegistres: number = 0;
  editHabitualSub!: Subscription;
  habitualOpenModalSub!: Subscription;
  usuariSub!: Subscription;

  constructor(private fb: FormBuilder, private dialog: MatDialog) {
    this.translate.get('CARREGADOR.SUBTITLE').subscribe((res: string) => {
      this.tooltipText = res;
    });
    this.editHabitualSub = this.habitualService.habitualAdmin$.subscribe(() => {
      this.fetchData(this.formData.value)
    });
    this.habitualOpenModalSub = this.habitualService.habitualOpenModal$.subscribe(({type, data, edit, openList}) => {
      const dialogRef = this.dialog.open(HabitualsEditComponent, {
        data: { type, data, edit, openList },
      });
      dialogRef.componentInstance.closeEvent.subscribe(async () => {
        this.dialog.closeAll();
      });
    })
    this.usuariSub = this.userService.saveUser$.subscribe(async ({ data, id }) => {
      const professional = id ? await lastValueFrom(this.userService.updateProfesional(data, id)) : await lastValueFrom(this.userService.createProfessional(data));
      if(professional.validacioResultat) {
        this.errorValidator(professional.validacioResultat[0], data, id);
      } else {
        this.dialog.closeAll();
        this.fetchData(this.formData.value);
      }
    })
  }

  formData: FormGroup = this.fb.group({
    codiPerfil: [''],
    dniUsuari	: [''],
    cognom: [''],
    adrecaElectronica: [''],
    estat: [''],
  });

  formHabituals: FormGroup = this.fb.group({
    text: ['']
  })

  async ngOnInit() {
    const usuari = localStorage.getItem('usuari') || '{}';
    const empresa = localStorage.getItem('empresa') || '{}';
    this.usuari = JSON.parse(usuari);
    this.empresa = JSON.parse(empresa);
    await this.fetchData({})
  }

  async fetchData(filters: any = {}) {
    Object.keys(filters).forEach(key => {
      if (filters[key] === "") {
        delete filters[key];
      }
    });
    if(filters["codiPerfil"]) {
      filters["codiPerfil"] =  filters["codiPerfil"] == "true" ? "PROF_ADMIN" : "PROF_GEST" 
    }
    if(this.currentPage === 0) {
      this.currentPage = 1;
    }
    const body = {
      "dadesPaginacio": {
        "numRegisters": this.itemsPerPage,
        "numPagina": this.currentPage,
        "ordreCamp": this.sortField,
        "ordreAsc": this.sortOrder
      },
      "filtresConsulta": filters
    }
    if(!this.usuaris) {
      body.filtresConsulta.multiCamp = this.formHabituals.value.text || "";
    }
    let data: ResponseMultiple = {
      dadesResultat: [],
      validacioResultat: undefined,
      dadesPaginacio: undefined
    };
    if(this.usuaris) {
      data = await lastValueFrom(this.userService.profesionalsFindBy(body))
    } else {
      switch(this.type) {
        case 'ruta':
          data = await lastValueFrom(this.rutaService.rutaFindBy(body))
          break;
        case 'vehicle':
          body.filtresConsulta.multiCamp = body.filtresConsulta.multiCamp.toUpperCase()
          data = await lastValueFrom(this.vehiclesService.vehicleFindBy(body))
          break;
        case 'transportista':
          data = await lastValueFrom(this.transportistaService.transportistaFindBy(body))
      }
    }

    this.dataSource = data.dadesResultat;
    this.totalRegistres = data.dadesPaginacio.totalRegisters
    this.totalPages = Math.ceil(
      data.dadesPaginacio.totalRegisters / this.itemsPerPage
    );
    if (this.currentPage > this.totalPages) {
      this.currentPage = this.totalPages;
    }
    this.pageNumberArr = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
  }

  newUsuari() {
    const dialogRef = this.dialog.open(UserEditComponent, {
      data: { user: null,  edit: false},
    });
    dialogRef.componentInstance.closeEvent.subscribe(async () => {
      await this.fetchData(this.formData.value);
      this.dialog.closeAll();
    });
  }
  
  editElement(user: Usuari) {
    const dialogRef = this.dialog.open(UserEditComponent, {
      data: { user,  edit: true},
    });
    dialogRef.componentInstance.closeEvent.subscribe(async () => {
      await this.fetchData(this.formData.value);
      this.dialog.closeAll();
    });
  }

  async deleteElement(user: Usuari) {
    user.estat = false;
    await(lastValueFrom(this.userService.deleteUsuari(user.empresaUsuariId)))
    await this.fetchData(this.formData.value);
  }

  async setUsuaris(valor: boolean) {
    this.usuaris = valor;
    this.sortField = this.usuaris ? "cognom" : "alias";
    this.sortOrder = true;
    this.formData.patchValue({
      codiPerfil: '',
      dniUsuari	: '',
      cognom: '',
      adrecaElectronica: '',
      estat: '',
    })
    this.formHabituals.patchValue({text: '',})
    await this.fetchData(this.formData.value);
  }

  async setHabituals(type: string) {
    this.type = type;
    this.dataSource = []
    this.formHabituals.patchValue({text: ''})
    await this.fetchData(this.formHabituals.value);
  }

  async goToPage(event: Event) {
    this.currentPage = +(event.target as HTMLSelectElement).value;
    this.usuaris ? await this.fetchData(this.formData.value) : await this.fetchData(this.formHabituals.value)
  }

  showMesFiltres() {
    this.mesFiltres = !this.mesFiltres;
  }

  async updateSortField(field: string) {
    this.currentPage = 1;
    if (this.sortField == field) {
      this.sortOrder = !this.sortOrder;
    } else {
      this.sortField = field;
    }
    await this.fetchData(this.formData.value);
  }

  async changeOrder() {
    this.currentPage = 1;
    this.sortOrder = !this.sortOrder;
    await this.fetchData(this.formData.value);
  }

  getValueByField(obj: any, field: string): any {
    return field.split('.').reduce((o, key) => (o ? o[key] : null), obj);
  }

  adminGestor(element: Usuari) {
    if(!element.codiPerfil) {
      return ""
    }
    return element.codiPerfil.includes("ADMIN") ? "Administrador" : "Gestor"
  }

  async clearFilter() {
    this.formData.patchValue({
      codiPerfil: '',
      dniUsuari	: '',
      cognom: '',
      adrecaElectronica: '',
      estat: '',
    });
    this.pageNumberArr = Array.from(
      { length: this.totalPages },
      (_, i) => i + 1
    );
    await this.fetchData(this.formData.value)
  }

  async setFavorit(element: any) {
    if (this.type === 'ruta') {
      const item: Ruta | undefined = this.dataSource.find((el: Ruta) => el.objectMantId === element.objectMantId);
      if (item) {
        item.favorit = !item.favorit;
        await lastValueFrom(this.rutaService.updateRuta(item));
      }
    }  
    if (this.type === 'transportista') {
      const item: Transportista | undefined = this.dataSource.find((el: Transportista) => el.objectMantId === element.objectMantId);
      if (item) {
        item.favorit = !item.favorit;
        await lastValueFrom(this.transportistaService.updateTransportista(item));
      }
    }
    if (this.type === 'vehicle') {
      const item: Vehicle | undefined = this.dataSource.find((el: Vehicle) => el.objectMantId === element.objectMantId);
      if (item) {
        item.favorit = !item.favorit;
        await lastValueFrom(this.vehiclesService.updateVehicle(item));
      }
    }
  }

  async previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      await this.fetchData(this.formData.value)
    }
  }

  async nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      await this.fetchData(this.formData.value)
    }
  }
  
  async onItemsPerPageChange(event: Event) {
    this.currentPage = 1;
    this.itemsPerPage = +(event.target as HTMLSelectElement).value;
    await this.fetchData(this.formData.value)
  }

  async applyFilter() {
    await this.fetchData(this.formData.value)
  }

  editHabitual(element: any, edit: boolean) {
    const dialogRef = this.dialog.open(HabitualsEditComponent, {
      data: { type: this.type, data: element, edit, openList: false },
    });
    dialogRef.componentInstance.closeEvent.subscribe(async () => {
      this.dialog.closeAll();
    });
  }

  newHabitual() {
    const dialogRef = this.dialog.open(HabitualsEditComponent, {
      data: { type: this.type, data: null, edit: true, openList: false },
    });
    dialogRef.componentInstance.closeEvent.subscribe(async () => {
      this.dialog.closeAll();
    });
  }

  deleteHabitual(element: any) {
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: { title: 'MODAL.ALERT', message: 'STMC.SEGUR_BORRAR', icon: 'warning', showCancel: true },
    });
    dialogRef.componentInstance.closeEvent.subscribe(async (result: {accept: boolean}) => {
      if(result.accept) {
        switch(this.type) {
          case 'ruta':
              await lastValueFrom(this.rutaService.deleteRuta(element.objectMantId))
              break;
            case 'transportista':
              await lastValueFrom(this.transportistaService.deleteTransportista(element.objectMantId))
              break;
            case 'vehicle':
              await lastValueFrom(this.vehiclesService.deleteVehicle(element.objectMantId))
              break;
        }
        await this.fetchData(this.formData.value);
      }
      this.dialog.closeAll();
    });
  }

  
  errorValidator(error: any, user: any, id: string | null) {
    const callback = () => {
      const dialogRef = this.dialog.open(UserEditComponent, {
        data: { user: {...user, empresaUsuariId: id}, edit: id != null },
      });
      dialogRef.componentInstance.closeEvent.subscribe(() => {
        this.dialog.closeAll();
      });
    };
    const dialogRef = this.dialog.open(ErrorModalComponent, {
      data: {
        title: 'ERRORS.'.concat(error.codiError),
        message: error.missatge,
        icon: 'warning',
        showCancel: false,
        callback,
      },
    });
    dialogRef.componentInstance.closeEvent.subscribe(
      async (result: { accept: boolean }) => {
        this.dialog.closeAll();
      }
    );
  }

  async sortFieldChange(event: any) {
    this.sortField = event.target.value;
    await this.updateSortField(this.sortField);
  }
}
